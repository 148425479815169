<template>
	<!-- 商户提现列表 -->
	<div class="storeWithdraw">
		<page-withdraw :platform="'store'"></page-withdraw>
	</div>
</template>
<script>
	import pageWithdraw from '@/components/layout/common/page-withdraw.vue'
	export default{
		components:{
			pageWithdraw
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style>
</style>
